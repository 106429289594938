body{
    color: $black-color;
    font-size: $font-size-base;
    font-family: $sans-serif-family;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.main{
    position: relative;
    background: $white-color;
}
/* Animations */
.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link,
.tag,
.tag [data-role="remove"]{
    @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.card a,
.bootstrap-switch-label:before{
    @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after{
    @include rotate-180();
}

.button-bar{
    display: block;
    position: relative;
    width: 22px;
    height: 1px;
    border-radius: 1px;
    background: $white-bg;

    & + .button-bar{
        margin-top: 7px;
    }

    &:nth-child(2){
        width: 17px;
    }
}

.separator-line{
    height: 2px;
    width: 44px;
    background-color: $default-color;
    margin: 20px auto;

    &.separator-primary{
        background-color: $primary-color;
    }
}

.section-space{
    height: 62px;
    display: block;
}
ngb-accordion{
    .card{
      box-shadow: none;
      background: transparent;
        .card-header{
          padding: .75rem 0;
          position: relative;
          [aria-expanded="true"] .arrows-1_minimal-down{
            @include rotate-180();
          }
          &:after{
              content: "";
              position: absolute;
              bottom: 0 ;
              width: 100%;
              height: 1px;
              background-color: $light-gray;
          }
          button{
            display: block;
            border-bottom: none !important;
            width: 100%;
            margin: 0;
            padding: 0;
            text-align: left;
            font-size: 14px;

            &,&:hover,&:focus,&:active{
              background: inherit !important;
              color: #444 !important;
            }
            &:hover,&:active{
              text-decoration: underline;
            }

              .arrows-1_minimal-down{
                float: right;
                position: relative;
                color: $primary-color;
                top: 1px;
                @include transition($general-transition-time, $transition-ease);
              }
          }
        }
        // .card-body{
        //     display: none;
        //     position: relative;
        //     overflow: hidden;
        //     &.show{
        //         display: block;
        //         // @include transition(300ms, linear);
        //     }
        // }
    }
    // .card:not(.card-plain):hover{
    //     transform: none!important;
    // }
}
