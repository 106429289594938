.testimonials-1{
    .description{
        margin-bottom: 50px;
    }
}

.section-testimonials,
.testimonials-2{
        background-image: none;

        .card-profile{
            .card-title,
            .card-description{
                text-align: left !important;
            }
        }
        .card-image{
            margin: 0;
        }
        .carousel{
            .carousel-inner{
                box-shadow: none;
            }

            .carousel-control-next{
                span{
                  background-image: none !important;
                  &:after{
                    content: "\ea3b";
                    color:  $light-black;
                    display: inline-block;
                    font: normal normal normal 14px/1 'Nucleo Outline';
                    font-size: inherit;
                    speak: none;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                  }
                }
            }
            .carousel-control-prev,
            {
                span{
                  background-image: none !important;
                  &:after{
                    content: "\ea3a";
                    color:  $light-black;
                    display: inline-block;
                    font: normal normal normal 14px/1 'Nucleo Outline';
                    font-size: inherit;
                    speak: none;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                  }
                }
            }
        }
        .testimonials-people{
            position: relative;

            img{
                position: absolute;
                border-radius: 50%;
                z-index: 4;
            }
            .left-first-person{
                left: 2%;
                top: 2%;
                height: 70px;
                width: 70px;
            }
            .left-second-person{
                left: 65%;
                top: 100%;
                height: 70px;
                width: 70px;
            }
            .left-third-person{
                left: -25%;
                top: 135%;
                height: 120px;
                width: 120px;
            }
            .left-fourth-person{
                left: 40%;
                top: 180%;
                height: 40px;
                width: 40px;
            }
            .left-fifth-person{
                left: 95%;
                top: 220%;
                height: 45px;
                width: 45px;
            }
            .left-sixth-person{
                left: 40%;
                top: 265%;
                height: 95px;
                width: 95px;
            }
            .right-first-person{
                right: 2%;
                top: 0;
                height: 60px;
                width: 60px;
            }
            .right-second-person{
                right: 30%;
                top: 60%;
                height: 70px;
                width: 70px;
            }
            .right-third-person{
                right: 95%;
                top: 95%;
                height: 50px;
                width: 50px;
            }
            .right-fourth-person{
                right: 66%;
                top: 145%;
                height: 40px;
                width: 40px;
            }
            .right-fifth-person{
                right: 90%;
                top: 210%;
                height: 100px;
                width: 100px;
            }
            .right-sixth-person{
                right: 15%;
                top: 240%;
                height: 70px;
                width: 70px;
            }
        }
    }
