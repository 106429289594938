.section{
    padding: 0px 0;
    position: relative;
    //background: $white-color;
    min-height: 79.1vh;
    padding-top: 2rem;
    padding-bottom: 1rem;
   

    .row + .category{
        margin-top: $margin-base-vertical;
    }
}
.section-navbars{
    padding-bottom: 0;
}

.section-full-screen{
    height: 100vh;
}
.section-signup{
    padding-top: 20vh;
}

.parallax-s{
    overflow: hidden;
    height: 500px;
    width: 100%;
}

.section-image{
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;

    .title,
    .card-plain .card-title{
        color: $white-color;
    }

    .nav-pills .nav-link{
        background-color: $white-color;

        &:hover,
        &:focus{
            background-color: $white-color;
        }
    }

    .info-title,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white-color;
    }
    .description,
    .info .icon:not(.icon-circle){
        color: $opacity-8;
    }
    .card:not(.card-plain){
        .info-title{
            color: $black-color;
        }
        .info p,
        .info .icon,
        .description{
            color: $dark-gray;
        }
    }

    .footer{
        color: $white-color;
    }
    .card-plain{
        [class*="text-"],
        ul li b{
            color: $white-color;
        }
        .category{
            color: rgba($white-color,.5);
        }
    }

    &:after{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0,0,0,.7);
    }

    .container{
        z-index: 2;
        position: relative;
    }
}

.page-header{
    min-height: 100vh;
    max-height: 999px;
    padding: 0;
    color: $white-color;
    position: relative;
    overflow: hidden;

    .page-header-image{
        position: absolute;
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: white;
    }

    & > .content{
        margin-top: 11%;
        text-align: center;
        margin-bottom: 50px;
    }

    .signup-page &{
        max-height: fit-content;
    }

    .content-center{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        padding: 0 15px;
        width: 100%;
        max-width: 880px;

    }

    footer{
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .container{
        height: 100%;
        z-index: 1;
    }

    .category,
    .description{
        color: $opacity-8;
    }

    &.page-header-small{
        min-height: 60vh;
        max-height: 440px;
    }

    &.page-header-mini{
        // min-height: 20vh;
        max-height: 40px;
    }

    .title{
        margin-bottom: 15px;
    }
    .title + h4{
        margin-top: 10px;
    }

    &:after,
    &:before{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        display: none;
        left: 0;
        top: 0;
        content: "";
    }

    &:before{
        background-color: rgba(0,0,0,.3);
    }

    &[filter-color="orange"]{
        @include linear-gradient(rgba($black-color,.20), rgba(224, 23, 3, 0.6));
    }
}

.clear-filter{
    &:after,
    &:before{
        display: none;
    }
}

.section-story-overview{
    padding: 50px 0;

    .image-container{
        height: 335px;
        position: relative;
        background-position: center center;
        background-size: cover;
        box-shadow: $box-shadow-raised;
        border-radius: .25rem;

        & + .category{
            padding-top: 15px;
        }

        &.image-right{
            z-index: 2;

            + h3.title{
                margin-top: 120px;
            }
        }

        &.image-left{
            z-index: 1;
        }

        img{
            width: 100%;
            left: 0;
            top: 0;
            height: auto;
            position: absolute;
        }

        &:nth-child(2){
            margin-top: 420px;
            margin-left: -105px;
        }
    }

    p.blockquote{
        width: 220px;
        min-height: 180px;
        text-align: left;
        position: absolute;
        top: 376px;
        right: 155px;
        z-index: 0;
    }
}

.section-nucleo-icons{
     .nucleo-container img{
        width: auto;
        left: 0;
        top: 0;
        height: 100%;
        position: absolute;
    }

     .nucleo-container{
        height: 335px;
        position: relative;
    }

     h5{
        margin-bottom: 35px;
    }

     .icons-container{
        position: relative;
        max-width: 450px;
        height: 300px;
        max-height: 300px;
        margin: 0 auto;

    }
     .icons-container i{
        font-size: 34px;
        position: absolute;
        left: 0;
        top: 0;
    }

     .icons-container i:nth-child(1){
        top: 5%;
        left: 7%;
    }

     .icons-container i:nth-child(2){
         top: 28%;
         left: 24%;
    }

     .icons-container i:nth-child(3){
         top: 40%;
    }

     .icons-container i:nth-child(4){
         top: 18%;
         left: 62%;
    }

     .icons-container i:nth-child(5){
         top: 74%;
         left: 3%;
    }

     .icons-container i:nth-child(6){
         top: 36%;
         left: 44%;
        font-size: 65px;
        color: #f96332;
        padding: 1px;
    }

     .icons-container i:nth-child(7){
         top: 59%;
         left: 26%;
    }

     .icons-container i:nth-child(8){
         top: 60%;
         left: 69%;
    }

     .icons-container i:nth-child(9){
        top: 72%;
        left: 47%;
    }

     .icons-container i:nth-child(10){
         top: 88%;
         left: 27%;
    }

     .icons-container i:nth-child(11){
         top: 31%;
         left: 80%;
    }

     .icons-container i:nth-child(12){
         top: 88%;
         left: 68%;
    }

     .icons-container i:nth-child(13){
         top: 5%;
         left: 81%;
    }

     .icons-container i:nth-child(14){
         top: 58%;
         left: 90%;
    }

     .icons-container i:nth-child(15){
        top: 6%;
        left: 40%;
    }
}

.section-images{
    max-height: 670px;
    height: 670px;

    .hero-images-container,
    .hero-images-container-1,
    .hero-images-container-2{
        margin-top: -38vh;
    }

    .hero-images-container{
        max-width: 670px;
    }

    .hero-images-container-1{
        max-width: 390px;
        position: absolute;
        top: 55%;
        right: 18%;

    }

    .hero-images-container-2{
        max-width: 225px;
        position: absolute;
        top: 68%;
        right: 12%;
    }
}

[data-background-color="gray"]{
    background-color: lighten($default-color, 40%);
}

[data-background-color="orange"]{
    background-color: $orange-bg;
}
[data-background-color="black"]{
    background-color: $black-color;
}

[data-background-color]:not([data-background-color="gray"]){
    color: $white-color;

    .separator-line{
        background-color: $opacity-5;
    }

    .footer{
        &.footer-white .footer-brand,
        &.footer-white ul li > a.nav-link:not(.btn-icon){
            color: initial;
        }
    }

    .card:not(.card-plain) .category,
    .card:not(.card-plain) .card-description,
    & .card:not(.card-plain) .category,
    & .card:not(.card-plain) .category{
        color: $dark-gray;
    }

    .card:not(.card-plain) .card-title,
    & .card:not(.card-plain) .card-title{
        color: initial;
    }

    .carousel .carousel-inner{
        box-shadow: none;
    }

    .title,
    .social-description h2,
    p,
    p.blockquote,
    p.blockquote small,
    h1,h2,h3,h4,h5,h6,
    a:not(.btn):not(.dropdown-item):not(.card-link),
    .icons-container i,
    &.card-pricing ul li,
    .info.info-horizontal .icon i,
    .card-pricing.card-plain ul li{
        color: $white-color;
    }

    &.card a:not(.btn):not(.dropdown-item){
        color: $white-color;

        &:hover,
        &:focus{
            border-color: $white-color;
        }
    }

    &.footer hr,
    &.card-pricing .icon i,
    &.card-pricing ul li{
        border-color: $opacity-2;
    }

    .card-footer .stats i,
    &.card-plain .category,
    .card-plain .category,
    .card-header:after{
        color: $opacity-5;
    }

    &.card-pricing ul li i,
    &.card-pricing ul li b,
    .card-pricing.card-plain ul li b,
    .card-category,
    .author span,
    .card-pricing.card-plain ul li i{
        color: $opacity-8 !important;
    }

    .separator{
        background-color: $white-color;
    }

    .navbar.bg-white p{
        color: $default-color;
    }

    .description,
    .social-description p{
        color: $opacity-8;
    }

    p.blockquote{
        border-color: $opacity-2;
    }

    //radio and checkboxes
    .checkbox label::before,
    .checkbox label::after,
    .radio label::before,
    .radio label::after{
        border-color: $opacity-2;
    }

    .checkbox label::after,
    .checkbox label,
    .radio label{
        color: $white-color;
    }

    .checkbox input[type="checkbox"]:disabled + label,
    .radio input[type="radio"]:disabled + label  {
        color: $white-color;
    }

    .radio input[type="radio"]:not(:disabled):hover + label::after,
    .radio input[type="radio"]:checked + label::after {
        background-color: $white-color;
        border-color: $white-color;
    }

    //inputs
    @include input-coloured-bg($opacity-5, $white-color, $white-color, $transparent-bg, $opacity-1, $opacity-2);

    .input-group-text,
    .form-group.form-group-no-border .input-group-text,
    .input-group.form-group-no-border .input-group-text{
        color: $opacity-8;
    }

    .subscribe-line{
        .form-control{
            background-color: $transparent-bg;
            border: 1px solid $light-gray;
            color: $black-color;

            &:last-child{
                border-left: 0 none;
            }
        }

        .input-group-text,
        .form-group.form-group-no-border .input-group-text,
        .input-group.form-group-no-border .input-group-text{
            color: $dark-background;
            border: 1px solid $light-gray;
        }
    }


    //buttons
    .btn.btn-simple{
        background-color: $transparent-bg;
        border-color: $opacity-5;
        color: $white-color;

        &:hover,
        &:hover,
        &:focus,
        &:active{
            background-color: $transparent-bg;
            border-color: $white-color;
        }
    }

    //tabs
    .nav-tabs{
        > .nav-item{
            >.nav-link{
                i.now-ui-icons{
                    color: $white-color;
                }
            }
        }
    }

    &.section-nucleo-icons .icons-container i:nth-child(6){
        color: $white-color;
    }
}

[class*="features-"],
[class*="team-"],
[class*="projects-"],
[class*="pricing-"],
[class*="testimonials-"],
[class*="contactus-"]{
    padding: 80px 0;
}

.list {
    // box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.11);
    margin: 20px 0px;
    border-radius: 10px;
    font-family: inherit;
    background: white;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.03); 
    transition-duration: 0.5s;
}
.list:hover{
}
.top-slider img{
    width: 100%;
    
}
.top-slider{
    margin-top: -32px;
    margin-bottom: 30px;
}
.top-slider .owl-controls{
    bottom: 30px;
}
.top-slider .owl-theme .owl-controls .owl-buttons div{
    background: rgba(0,0,0,0.2);  
    opacity: 1;
}
.list .mat-chip.mat-standard-chip{
    background-color: #cfeabe;
}
.list.unapproved{
    color:#fa4c06
}
h2.section-title{
    padding: 10px;
    color: #fa4c06;
    font-weight: bold;
    margin-bottom:10px !important;
}
.navbar a:not(.btn):not(.dropdown-item){
    color: #292929;
    padding: 0;
}
.dropdown-menu{
    background: white;
    box-shadow: 0px 14px 20px 16px rgba(0, 0, 0, 0.02);
}
.dropdown-menu:before{
    border-bottom: .4em solid white;
}
.cart{

}
.cart h2.section-title{

}
.example-spacer {
    flex: 1 1 auto;
  }
  .mat-list-base .mat-list-item{
      font-family: inherit
  }
  .mat-list-base .mat-list-item .mat-line span{
      color:#fa4c06;
      float: right;
  }
.mat-card{
    box-shadow: none !important;
    font-family: inherit !important;
}
.mat-card-title{
    font-size: 20px !important;
    font-weight: 600 !important;
}
.mat-form-field,.mat-button{
    font-family: inherit !important;
}
.mat-card-actions{
    display: flex !important;
}
.no-img{
    margin: 0 0 0 0;
    width: 100%;
    display: block;
    text-align: center;
    background: #f3f3f3;
    padding: 50px 0;
}
.no-img.mat-card-image i {
    font-size: 56px;
    color: #e4e4e4;
}
.product-page-bar{
    border-radius: 10px;
    font-family: inherit;
    background: white;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.03); 
    transition-duration: 0.5s;    
}
.product-page-bar .search-form{
    width: 100%;
}
.product-page-bar .search-form .mat-form-field-wrapper{

}
.product-page-bar .mat-toolbar{
    background: transparent;
}
.product .mat-card-subtitle:nth-child(2){
    font-weight: bold;
}
.product .mat-card{
    text-align: center;
}

.sidebar-categories .mat-button{
text-align: left;
}
.fixed-top{
    z-index: 1000 !important;
}
.fixed-top.navbar-transparent img{
    max-height: none;
}
.fixed-top img{
    max-height: 50px;
}
.order-qty .mat-toolbar-row{
    flex-wrap: wrap;
    height: auto !important;
}
.mat-toolbar.order-qty{
    background: transparent
}
.order-qty .mat-toolbar-row .quantity-box{
    display: block;
}
.order-qty .error-field{
    width: 100%;
    left: 0;
    bottom: 0;
    background: orange;
    margin-bottom: 10px;
    border-radius: 2px;
    padding: 0px 10px;    
}
input#txtFileUpload{
    max-width: 200px;
    font-size: 11px;
    background: transparent;
}
.mat-table{
    width: 100%;
    box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.03);
    font-family: inherit !important;
    //border-radius: 20px;
    overflow: hidden;
}
.block-ui-wrapper{
    background: rgba(83, 15, 15, 0.7) !important;
}
.customer-select .mat-radio-label{

}
.selected-category{
    background: #fa4c06 !important;
    color: white !important;
}
.sidebar-filter-btn i{
    display: none;
}
.selected-category i{
    display: block !important;
}
.catalog-img {
    height: 190px;
}
.catalog-img img{
    max-height: 100%;
    width: auto;;
}
.product .product-title{
    min-height: 81px;
    font-size: 18px !important;
    text-transform: capitalize;
}
th.mat-header-cell{
    font-weight: bold;
    font-size: 15px;
}
.list h2 small{
    font-weight: 800;    
}
.list h3 small{
    font-weight: 800;    
    font-size: 13px;
}
.list h3{
    margin-bottom: 0px;
}
.navbar.navbar-transparent,.navbar-expand-lg{
    display: block;
}
.navbar.navbar-transparent{
    padding-top: 0px !important;
}
.access-spinner{
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
}
.access-spinner div.access-spinner-holder{
    display: table-cell;
    width: 100%;
    text-align: center;
    vertical-align: middle;
}
.access-spinner .mat-progress-spinner{
    margin: auto;
}
.access-spinner span{

}
.access-spinner .access-spinner-holder{
    min-height: 300px;
}
.access-spinner .access-spinner-holder div:first-child{
    // max-width: 80%;
    margin: auto;
    padding: 20px;
    min-height: 300px;
    // border-radius: 20px;
    // border: 1px solid #dedddd;
    min-height: 429px;
// margin-top: 30px
}
.access-spinner .access-spinner-holder .login{
    background:#fde8e6;
}
.access-spinner div.access-spinner-holder i{
    font-size: 100px;
}
.landing-header{
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
    z-index: 999;
    position: relative;

}
.landing-header .mat-toolbar-row{
    background: transparent;
    font-family: century gothic;
    height: auto;
}
.about{
    padding: 69px;
    background: white;
}
body{
    font-family: Arial !important;
    background: #f8fafb;
}
.favorite{
    font-size: 35px;
    width: auto !important;
}
.favorite.mat-icon.mat-primary{
    color:#4a4a4a;
}
.favorite.mat-badge-warn .mat-badge-content{
    width: 48px;
    height: auto;
    line-height: 18px;
    border-radius: 3px;
    background: #fa4c07;
    /* text-shadow: 0 0 black; */
    font-size: 14px;
}
.home_sections .list{
    padding: 15px;
}
.landing-header .mat-toolbar-row:nth-child(2){
    padding: 10px;
}
.landing-header .mat-toolbar-row:nth-child(2) .mat-button{
    font-size: 19px;
    font-weight: bold;
    color:#6f6f6f;
}
.landing-header .mat-toolbar-row:nth-child(2) .mat-button.mat-warn{
    color: #f44336;
}
.years{
    max-width:84px ;
}
.landing-header .years{
    position: absolute;
    margin: 1%;
}
.list tr{
    cursor: pointer;
}
.list tr:hover {
    background: oldlace;
}
.mat-card-subtitle{
    color: inherit !important
}
.image-viewer{
text-align: center;
}
.image-viewer img{
    max-height: 500px;
    width: auto;
}
.empty-content{

}
.empty-content h3 i.material-icons{
    font-size: 65px;
    color:#fec46c;
}
td.mat-cell,th.mat-cell{
    padding: 10px !important;
}
.tab-row-highlight{
    color: white;
    font-weight: bold;
    background: #ec6c39;
    /* border-bottom: 2px solid orange !important; */
}

.tab-row-highlight.first{
    background: #ff9367;
}
.active-menu{
    color: #fa4c06 !important;
}
.mat-stepper-horizontal, .mat-stepper-vertical{
    font-family: inherit;
}
.mat-step-header .mat-step-label, .mat-step-header .mat-step-optional{
    font-size: 20px;

}

.mat-step-header .mat-step-label.mat-step-label-active {
    color: #f3855a;
}
.retailer-form .mat-form-field-wrapper{
    margin: 0 10px !important;
}
.retailer-form .mat-horizontal-stepper-header-container{
    border-bottom:1px solid #f3855a;
}
.retailer-form .mat-stepper-horizontal-line{
    flex: 0.1 !important;
}
.mat-step-header .mat-step-icon{
    background-color: #3f51b5 !important;
    color: white;
}
.retailer-form .mat-step-header .mat-step-icon-selected{
    background: #f44336 !important;
    color: white;
}
.mat-step-icon-selected .mat-step-icon{

}
.retailer-form .mat-horizontal-content-container{
    padding: 24px !important;
}
.retailer-form .mat-list-base .mat-list-item .mat-list-item-content{
    color: #fa4c07;
}

.location-forms h4{
    padding: 10px;
    margin: 0;
}
.location-forms h4 i{
    background: orange;
    border-radius: 20px;
    padding: 3px;
    border: 3px solid orange;
}
.location-forms .form{
    background: #f8fafb;
    margin-bottom: 20px;
    padding: 20px 10px;
    border: 1px solid #dadada;
}
.retailer-form .mat-stepper-horizontal-line{
    position: relative;
}
.mat-stepper-horizontal-line::after {
    content: '';
    width: 10px;
    height: 10px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: -5px;
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
}
.order-viewer{
    background: transparent !important;
    border-bottom: 3px solid;
}
.order-viewer-list .mat-list-item .mat-list-icon{
    width: 44px !important;
}
address i{
    background: orange;
    border-radius: 20px;
    padding: 2px;    
}
.owl-theme .owl-controls{

    // position: absolute;
    z-index: 999;
    width: 100%;
    /* top: -30px; */
    //margin-top: -27px !important;
}
.owl-theme .owl-controls .owl-page span{
    background: #fa4c06 !important;
}
.modal-content{
    padding: 10px;
}
.owl-item{
    transition-duration: 0.2s;
}
/*.owl-item:hover{
    transform: scale(1.1);
}*/
.catalog-img{
    overflow: hidden;
}
.catalog-img img{
    transition-duration: 0.2s;
}
.product .mat-card:hover{
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.05) !important;
}
.product:hover .catalog-img img{
    transform: scale(1.1);
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-thumb {
    background: rgba(252,22,11,0.5);
    border-radius: 50px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.retailer-request{
    width: 100%;
    padding: 100px;
    background: #ffffff;
    border-bottom: 10px solid rgb(253, 232, 230);
}
.retailer-request h4{
    margin-top: 0;
}
.home-icon i{
    font-size: 100px;
}
.alert.alert-danger{
    background-color: #fdf5e6 !important;
    color: #fa4c07 !important;
}
.footer-bar .mat-toolbar-row{
    height: auto;
}
.footer-bar .mat-card-image{
    width: auto;    
}
.footer-bar .mat-toolbar h4{
    font-family: century gothic;
}
.footer-bar.mat-toolbar{
    background: transparent;
    padding: 0px 0 50px;
}
.footer-bar .mat-card{
    background: transparent;
    min-width: 20%;
}
.footer{
    padding-bottom: 0px;
}
.footer-bottom{
    padding: 20px;
    background: #1d1d1d;
    font-size: 15px;
}
.footer-bar .mat-list-base .mat-list-item{
    color: white !important;
    font-family: arial;
    font-size: 19px;
}
.au-footer .footer-bar .mat-list-base .mat-list-item{
    color: #252525 !important;
}
.footer-bar .fa{
    font-size: 28px;
    color: white;
    margin-right: 10px;
}
.au-footer .footer-bar .fa{
    color: #252525;
}
.partners img{
margin-right: 10px;
}
.mat-list-base .mat-list-item h4.mat-line{
    font-weight: bold;
}
.no-padding{
    padding: 0px !important;
}
code{
    color: #fa4c07; 
}
.no-margin,.no-margin .mat-card-header-text{
    margin: 0px !important
}
.mat-drawer-backdrop.mat-drawer-shown{
    background-color: rgba(0,0,0,0.1);
}
.mat-drawer-inner-container{
    padding: 83px;
}
.mat-drawer{
    background:#f3eceb;
}
.mat-tree{
    background: transparent;
}
.mat-nested-tree-node, .mat-tree-node{
    font-size: 19px;
}
.help-comp .mat-tab-label-active {
    color: #f44336!important;
    opacity: 1;
}
.help-comp .mat-tab-label{
    font-size: 19px;
}
.close{
    background: #2f2f2f;
}
.Image-banner-text{
    background: #d3d619;
    min-height: 150px;
    padding-top: 50px;
    font-weight: bold;
    font-size: 26px;
    color: #4a4242;   
}
.container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90vh;
  }
.button-wrap input[type="file"] {
    position: absolute;
    /* z-index: -1; */
    top: 3px;
    left: 2px;
    font-size: 15px !important;
    color: black;
    max-width: none !important;
    font-weight: bold;
}


.button-wrap {
  position: relative;
}

.new-button {
    display: inline-block;
    padding: 5px 14px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #fa4c07;
    font-size: 16px;
    color: #fff;
    z-index: 999;
    position: relative;
}
.pagination-danger ul{
    margin-bottom: 0rem;
}
.paginations{
    min-height: 44px !important;
    font-size: 14px;   
}
.paginations .mat-toolbar-row{
    height: 44px !important;   
}
.page-us-bg{
    background-size: 100%;
    background-image: url(/assets/img/us-bg.jpg);
    /* height: 100%; */
    background-repeat: no-repeat;
    background-position: 0 13vh;
    background-attachment: fixed;
}
.page-eu-bg{
    background-size: 100%;
    background-image: url(/assets/img/us-bg.jpg);
    /* height: 100%; */
    background-repeat: no-repeat;
    background-position: 0 13vh;
    background-attachment: fixed;
}
.page-ca-bg{
    background-size: 100%;
    background-image: url(/assets/img/us-bg.jpg);
    /* height: 100%; */
    background-repeat: no-repeat;
    background-position: 0 13vh;
    background-attachment: fixed;
}
.page-au-bg{
    background-size: 100%;
    background-image: url(/assets/img/us-bg.jpg);
    /* height: 100%; */
    background-repeat: no-repeat;
    background-position: 0 13vh;
    background-attachment: fixed;
} 
.au-footer{
    color:#252525;
}
.footer h4{
    color:white;
}
.footer .au-footer h4{
color:#252525;
}
.row-hero{
    height: auto !important ;
}

.impNotice{
    background: #fa4c07;
    color: #fff !important;
    border: solid 1px #fa4c07;
}

.impNotice:hover{
    background: #fff;
    color: #fa4c07 !important;
    border: solid 1px #fa4c07;
}

.customer-text
{
    margin: 5px !important;
    white-space:pre-wrap !important;
    line-height: 25px !important;
    padding: 10px !important;
}

@import 'sections/_headers';
@import 'sections/_features';
@import 'sections/_blogs';
@import 'sections/_team';
@import 'sections/_projects';
@import 'sections/_pricing';
@import 'sections/_testimonials';
@import 'sections/_contactus';

@import 'sections/_social-subscribe-lines';
